$(document).ready(function() {
    var $navButton = $('#navigation-button');
    var $menu = $('.menu');
    var $overlay = $('#menu-overlay');
    var $menuItem = $('.menu__item--hasSubmenu');

    $navButton.click(function() {
        $menu.toggle();
        $overlay.toggle();
    });

    $overlay.click(function() {
        $menu.toggle();
        $overlay.toggle();
    });
    $menuItem.click(function(e) {
        e.preventDefault();
        var $this = $(this);
        var menuClass = 'show-menu';
        $menuItem.not($this).removeClass(menuClass);
        if($this.hasClass(menuClass)) {
            $this.removeClass(menuClass);
        } else {
            $this.addClass(menuClass);
        }
    });
});