$(document).ready(function (e) {

    var $employeeSelect = $('#employee-select');
    var $employeesItem = $('.employees-item');

    var container = $(".employees__grid").isotope({
        itemSelector: ".employees-item",
        masonry: {
            gutter: 15
        },
        percentPosition: true
    });

    container.imagesLoaded().progress(function () {
        container.isotope('layout');
    });

    $("#employee-select").change(function () {
        if (this.value === "*") {
            container.isotope({filter: ""});
        } else {
            container.isotope({filter: this.value});
        }
    });
});