$(document).ready(function() {
   initializeGallery();
    var $gallerySelect = $('#gallery-select');

    $gallerySelect.change(function() {
        var id = $(this).val();
        swapGallery(id);
    });

    $('.gallery-button').click(function() {
        $(this).hide();
        $('.mobile-hidden').show();
    });
});

$('.gallery-select').click(function(e) {
   e.preventDefault();
   var id = $(this).attr('id');
   swapGallery(id);
});

function swapGallery(id) {
    $.ajax('gallery',
        {
            data: {
                id: id
            },
            dataType: 'json',
            error: function(){

            },
            success: function(data) {
                var $galleryWrapper = $('.gallery__images');
                $galleryWrapper.empty();
                $.each(data, function(index, value) {
                    var $wrapper = $('<div class="gallery__image">');
                    var $img = $('<img src="' + value + '">');
                    var $link = $('<a class="gallery-overlay lightbox" href="' + value + '">');
                    var $icon = $('<i class="fa fa-plus-square fa-2x">');
                    var $span = $('<span>Bild vergrößern</span>');
                    $wrapper.append($img);
                    $link.append($icon);
                    $link.append($span);
                    $wrapper.append($link);
                    $galleryWrapper.append($wrapper);

                    $('.gallery-select').removeClass('active');
                    $('#' + id).addClass('active');
                    $('.gallery-button').hide();
                });
                initializeGallery();
            }
        }
    );
}

function initializeGallery() {
    $(".lightbox").magnificPopup({
        type: "image",
        tClose: 'Schließen',
        tLoading: 'Wird geladen...',
        gallery: {
            enabled: true,
            tPrev: 'Zurück',
            tNext: 'Weiter',
            tCounter: '%curr% von %total%'
        }
    });
}